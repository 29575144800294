// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-beliefs-js": () => import("./../../../src/pages/beliefs.js" /* webpackChunkName: "component---src-pages-beliefs-js" */),
  "component---src-pages-connect-adult-js": () => import("./../../../src/pages/connect/adult.js" /* webpackChunkName: "component---src-pages-connect-adult-js" */),
  "component---src-pages-connect-children-js": () => import("./../../../src/pages/connect/children.js" /* webpackChunkName: "component---src-pages-connect-children-js" */),
  "component---src-pages-connect-youth-js": () => import("./../../../src/pages/connect/youth.js" /* webpackChunkName: "component---src-pages-connect-youth-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-only-in-a-church-js": () => import("./../../../src/pages/only-in-a-church.js" /* webpackChunkName: "component---src-pages-only-in-a-church-js" */),
  "component---src-templates-messages-js": () => import("./../../../src/templates/messages.js" /* webpackChunkName: "component---src-templates-messages-js" */)
}

